import { calculatePersonalLoan } from '../components/loan-calculations';

const personalLoanCalculator = () => {
    const $calculator = document.getElementById('personal-loan-calculator');
    if(!$calculator) {
        return;
    }

    calculatePersonalLoan();

    const updateSliderBg = input => {
        const value = (input.value-input.min)/(input.max-input.min)*100
        input.style.background = 'linear-gradient(to right, var(--wp--custom--colors--site-primary) 0%, var(--wp--custom--colors--site-primary) ' + value + '%, #ececec ' + value + '%, #ececec 100%)'
    }

    //eventslisteners
    const $inputs = $calculator.querySelectorAll('input[type=range]');
    $inputs.forEach(input => {
        updateSliderBg(input);
        input.addEventListener('input',() => {
            calculatePersonalLoan();
            updateSliderBg(input);
        })
    });
}

export default personalLoanCalculator;
