import currency from 'currency.js'
//calculate function
const calculate = (amount, interest, terms) => {
    const monthlyInterest = Number(interest) / 100 / 12
    const x = Math.pow(1 + monthlyInterest, terms)

    return {
        amount: Number(amount),
        monthly: x === 1 ? amount / terms : (amount * x * monthlyInterest) / (x - 1),
        interest: monthlyInterest
    }
}
export const currencyFormatter = ($calculator) => {
    if (!$calculator) return
    //currency formatting
    const currencyPosition = $calculator.querySelector('input[name=currency-position]').value
    const currencySymbol = $calculator.querySelector('input[name=currency-symbol]').value
    const thousandSeparator = $calculator.querySelector('input[name=thousand-separator]').value
    const decimalSeparator = $calculator.querySelector('input[name=decimal-separator]').value

    return {
        separator: thousandSeparator,
        decimal: decimalSeparator,
        symbol: ` ${currencySymbol}`,
        precision: 0,
        pattern: currencyPosition === 'before' ? '!#' : '#!',
        negativePattern: currencyPosition === 'before' ? '-!#' : '-#!'
    }
}
export const calculateLoanProvider = () => {
    //calculator form
    const $calculator = document.getElementById('loan-provider-calculator')

    //currency format
    const currencyFormat = currencyFormatter($calculator)

    //current loan values
    const $loan = $calculator.querySelector('#lp-loan')

    //vars
    let monthlyPayment = 0
    let interestAmount = 0
    let totalLoanPayment = 0

    //get loan inputs
    const $amount = $loan.querySelector('input#lp-loan-amount')
    const $interest = $loan.querySelector('input#lp-loan-interest')
    const $terms = $loan.querySelector('input#lp-loan-terms')
    //get input values
    const amount = $amount.value
    const interest = $interest.value
    const terms = $terms.value

    const calculation = calculate(amount, interest, terms)

    monthlyPayment += calculation.monthly
    interestAmount += calculation.monthly * terms - amount
    totalLoanPayment += calculation.monthly * terms

    //output elements
    const $currentMonthlyPayment = $calculator.querySelector('#lp-current-monthly-payment span')
    const $currentTotalinterest = $calculator.querySelector('#lp-current-total-interest span')
    const $currentTotal = $calculator.querySelector('#lp-current-total span')

    //update value field
    $amount.closest('.input-group').querySelector('.value').innerHTML = currency(amount, currencyFormat).format()
    $interest.closest('.input-group').querySelector('.value').innerHTML = `${interest.replace(
        '.',
        currencyFormat.decimal
    )} %`
    $terms.closest('.input-group').querySelector('.value').innerHTML = terms + ' ' + window.translations.months

    // If the result is a finite number, the user's input was good and
    // we have meaningful results to display
    if (isFinite(monthlyPayment)) {
        // Fill in the output fields
        $currentMonthlyPayment.innerHTML = currency(monthlyPayment, currencyFormat).format()
        $currentTotalinterest.innerHTML = currency(interestAmount, currencyFormat).format()
        $currentTotal.innerHTML = currency(totalLoanPayment, currencyFormat).format()
    } else {
        // Result was Not-a-Number or infinite, which means the input was
        // incomplete or invalid. Clear any previously displayed output.
        $currentMonthlyPayment.innerHTML = '' // Erase the content of these elements
        $currentTotalinterest.innerHTML = ''
        $currentTotal.innerHTML = ''
    }
}

export const calculatePersonalLoan = () => {
    //calculator form
    const $calculator = document.getElementById('personal-loan-calculator')

    //currency format
    const currencyFormat = currencyFormatter($calculator)

    //current loan values
    const $loan = $calculator.querySelector('#pl-loan')

    //vars
    let monthlyPayment = 0
    let interestAmount = 0
    let totalLoanPayment = 0

    //get loan inputs
    const $amount = $loan.querySelector('input#pl-loan-amount')
    const $interest = $loan.querySelector('input#pl-loan-interest')
    const $terms = $loan.querySelector('input#pl-loan-terms')
    //get input values
    const amount = $amount.value
    const interest = $interest.value
    const terms = $terms.value

    const calculation = calculate(amount, interest, terms)

    monthlyPayment += calculation.monthly
    interestAmount += calculation.monthly * terms - amount
    totalLoanPayment += calculation.monthly * terms

    //output elements
    const $currentMonthlyPayment = $calculator.querySelector('#pl-current-monthly-payment').querySelector('span')
    const $currentTotalinterest = $calculator.querySelector('#pl-current-total-interest').querySelector('span')
    const $currentTotal = $calculator.querySelector('#pl-current-total').querySelector('span')

    //update value field
    $amount.closest('.input-group').querySelector('.value').innerHTML = currency(amount, currencyFormat).format()
    $interest.closest('.input-group').querySelector('.value').innerHTML = `${interest.replace(
        '.',
        currencyFormat.decimal
    )} %`
    $terms.closest('.input-group').querySelector('.value').innerHTML = terms + ' ' + window.translations.months

    // If the result is a finite number, the user's input was good and
    // we have meaningful results to display
    if (isFinite(monthlyPayment)) {
        // Fill in the output fields
        $currentMonthlyPayment.innerHTML = currency(monthlyPayment, currencyFormat).format()
        $currentTotalinterest.innerHTML = currency(interestAmount, currencyFormat).format()
        $currentTotal.innerHTML = currency(totalLoanPayment, currencyFormat).format()
    } else {
        // Result was Not-a-Number or infinite, which means the input was
        // incomplete or invalid. Clear any previously displayed output.
        $currentMonthlyPayment.innerHTML = '' // Erase the content of these elements
        $currentTotalinterest.innerHTML = ''
        $currentTotal.innerHTML = ''
    }
}

export const calculateRefinance = () => {
    //refinance form
    const $calculator = document.getElementById('refinance-loan-calculator')

    //currency format
    const currencyFormat = currencyFormatter($calculator)

    //new loan values
    const $newLoanAmount = $calculator.querySelector('#rf-new-amount')
    const $newInterest = $calculator.querySelector('#rf-new-interest')
    const $newTerms = $calculator.querySelector('#rf-new-terms')

    //vars
    let totalLoanAmount = 0
    let totalMonthlyPayment = 0
    let totalInterestAmount = 0
    let totalLoanPayment = 0

    //get loans
    const $loans = $calculator.querySelectorAll('div[id*=rf-loan]')
    //loop through loans and save values
    $loans.forEach(($loan) => {
        // inputs
        const $amount = $loan.querySelector('input[id*=rf-loan-amount]')
        const $interest = $loan.querySelector('input[id*=rf-loan-interest]')
        const $terms = $loan.querySelector('input[id*=rf-loan-terms]')
        //  input values
        const amount = $amount.value
        const interest = $interest.value
        const terms = $terms.value

        //update value field
        $amount.closest('.input-group').querySelector('.value').innerHTML = currency(amount, currencyFormat).format()
        $interest.closest('.input-group').querySelector('.value').innerHTML = `${interest.replace(
            '.',
            currencyFormat.decimal
        )} %`
        $terms.closest('.input-group').querySelector('.value').innerHTML = terms + ' ' + window.translations.months

        // calculate
        const calculation = calculate(amount, interest, terms)

        // accumulate totals
        totalLoanAmount += calculation.amount
        totalMonthlyPayment += calculation.monthly
        totalInterestAmount += calculation.monthly * terms - amount
        totalLoanPayment += calculation.monthly * terms
    })

    //get new loan values
    const newLoan = calculate($newLoanAmount.value, $newInterest.value, $newTerms.value)

    //new loan vars
    const newTotalInterest = newLoan.monthly * $newTerms.value - totalLoanAmount

    //output elements
    const $savingsMonthly = $calculator.querySelector('#rf-monthly-savings').querySelector('span')
    const $totalInterestSavings = $calculator.querySelector('#rf-total-interest-savings').querySelector('span')

    const $currentMonthlyPayment = $calculator.querySelector('#rf-current-monthly-payment')
    const $currentTotalinterest = $calculator.querySelector('#rf-current-total-interest')
    const $currentTotal = $calculator.querySelector('#rf-current-total')

    const $newMonthlyPayment = $calculator.querySelector('#rf-new-monthly-payment').querySelector('span')
    const $TableNewMonthlyPayment = $calculator.querySelector('#rf-table-new-monthly-payment')
    const $newTotalInterest = $calculator.querySelector('#rf-new-total-interest')
    const $newTotal = $calculator.querySelector('#rf-new-total')

    //continue if our calculations returned a number
    if (isFinite(totalMonthlyPayment - newLoan.monthly)) {
        //check if savings are positive or negative
        if (totalMonthlyPayment - newLoan.monthly > 0) {
            $calculator.querySelector('#rf-monthly-savings .negative').style.display = 'none'
            $calculator.querySelector('#rf-monthly-savings .positive').style.display = 'block'

            $savingsMonthly.innerHTML = currency(totalMonthlyPayment - newLoan.monthly, currencyFormat).format()
        } else {
            $calculator.querySelector('#rf-monthly-savings .negative').style.display = 'block'
            $calculator.querySelector('#rf-monthly-savings .positive').style.display = 'none'

            $savingsMonthly.innerHTML = currency(newLoan.monthly - totalMonthlyPayment, currencyFormat).format()
        }
        if (totalInterestAmount - newTotalInterest > 0) {
            $calculator.querySelector('#rf-total-interest-savings .negative').style.display = 'none'
            $calculator.querySelector('#rf-total-interest-savings .positive').style.display = 'block'

            $totalInterestSavings.innerHTML = currency(totalInterestAmount - newTotalInterest, currencyFormat).format()
        } else {
            $calculator.querySelector('#rf-total-interest-savings .negative').style.display = 'block'
            $calculator.querySelector('#rf-total-interest-savings .positive').style.display = 'none'

            $totalInterestSavings.innerHTML = currency(newTotalInterest - totalInterestAmount, currencyFormat).format()
        }

        // Fill in the output fields
        $currentMonthlyPayment.innerHTML = currency(totalMonthlyPayment, currencyFormat).format()
        $currentTotalinterest.innerHTML = currency(totalInterestAmount, currencyFormat).format()
        $currentTotal.innerHTML = currency(totalLoanPayment, currencyFormat).format()

        $newMonthlyPayment.innerHTML = currency(newLoan.monthly, currencyFormat).format()
        $newTotalInterest.innerHTML = currency(newTotalInterest, currencyFormat).format()
        $newTotal.innerHTML = currency(newLoan.monthly * $newTerms.value, currencyFormat).format()

        $TableNewMonthlyPayment.innerHTML = currency(newLoan.monthly, currencyFormat).format()
    } else {
        // Erase the content of these elements
        $savingsMonthly.innerHTML = ''
        $totalInterestSavings.innerHTML = ''
        $currentMonthlyPayment.innerHTML = ''
        $currentTotalinterest.innerHTML = ''
        $currentTotal.innerHTML = ''
        $newMonthlyPayment.innerHTML = ''
        $newTotal.innerHTML = ''
        $newTotalInterest.innerHTML = ''
        $TableNewMonthlyPayment.innerHTML = ''
    }
}
